import {
    FETCH_THANKYOU_REQUEST,
    FETCH_THANKYOU_RESPONSE,
    FETCH_THANKYOU_FAILURE,
    ThankyouState,
    ThankyouActionTypes,
  } from '../types/Thankyou';
  
  const initialState: ThankyouState = {
    thankyou: null,
    isFetchingThankyou: false,
    fetchingThankyouFailed: false,
  };
  
  const ThankyouReducer = (
    state = initialState,
    action: ThankyouActionTypes,
  ): ThankyouState => {
    switch (action.type) {
      case FETCH_THANKYOU_REQUEST:
        return {
          ...state,
          thankyou: null,
          isFetchingThankyou: true,
          fetchingThankyouFailed: false,
        };
      case FETCH_THANKYOU_RESPONSE:
        return {
          ...state,
          thankyou: action.payload || [],
          isFetchingThankyou: false,
          fetchingThankyouFailed: false,
        };
      case FETCH_THANKYOU_FAILURE:
        return {
          ...state,
          thankyou: null,
          isFetchingThankyou: false,
          fetchingThankyouFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default ThankyouReducer;
  