export const INVOICES = {
    "invoices": [
      {
        "invoice_no": "0123456",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/09/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123457",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/09/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123458",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/09/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123459",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "01234510",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123411",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123412",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123413",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
      {
        "invoice_no": "0123414",
        "reference": "Ref: Nibh Pellentesque Tellus Elit Fringilla",
        "due_date": "20/08/2024",
        "amount": "12345.00",
        "outstanding": "0.00"
      },
    ]
  };  