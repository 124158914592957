import React, { useState } from "react";

import './ContextMenu.scss';
type Props = {
    x: number,
    y: number,
    show: boolean,
    options: any[],
    clickEvent: any
}

const ContextMenu = (props: Props) => {

    const {
        x, y, show, options,clickEvent
    } = props;

    const menuPosition = {
        x: x,
        y: y
    };

    const isMenuVisible = show;


    const closeMenu = () => {
        clickEvent( null );
    };

    const handleClick = ( e:any, option : any) => {
        e.preventDefault();
        e.stopPropagation(); 
        clickEvent( option );
    };

    return (
        <div>{isMenuVisible && (
            <div className="context-menu-container" onClick={closeMenu}>

                <ul
                    className="context-menu"
                    style={{ top: menuPosition.y, left: menuPosition.x }}
                >
                    {
                        options && options.map((option, index) => {
                            return <li key={index} onClick={(e) => handleClick( e, option )}><span className="icon">{option.icon}</span><span>{option.text}</span></li>
                        })
                    }
                </ul>

            </div>
        )
        }
        </div>
    );
};

export default ContextMenu;
