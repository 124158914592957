import { useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';
import { useLocation } from 'react-router-dom';
import { setTitleCase } from '../helpers/functions';
import { config } from '../config/config';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';

const useGtag = () => {
  let location = useLocation();


  
  useEffect(() => {
    let cookie = Cookies.get('cookieconsentCookie');


    if ( config.gtag == '' ){
      return;
    }

    if (cookie === 'false') {
      return; // don't mount the gtag script
    }

    //Don't do any logging on localhost
    if (window.location.host == 'localhost:3000'){
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.gtag;
    script.async = true;
    script.id = 'tagmanager';
    let script2 = document.createElement('script');
    script2.id = 'tagmanager-script';
    script2.innerText = 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'' + config.gtag + '\');';

    document.body.appendChild(script);
    document.body.appendChild(script2);

    return () => {
    
    //  document.body.removeChild(script);
      //document.body.removeChild(script2);
    };
  },
  [location],
  );
};


export const usePageViews = () => {
  let location = useLocation();
  useEffect(
    () => {
      let path = location.pathname.split('/');
      for (let i = 0; i < path.length; i++){
        path[i] = setTitleCase(path[i]);
      }

      let str = (location.search.length > 0 ? location.pathname.replace('/', '') + '?' + location.search.replace('?', '') : path.join(' '));
      str = str.trim().length > 0 ? ' - ' + str : str;
      

      document.title = config.companyName + ' '  + str;
      
    },
    [location],
  );
};

export default useGtag;