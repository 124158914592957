import React, { useEffect, useState } from 'react';
import './View.scss';
import Header from '../Header';
import Footer from '../Footer';
import MobileMenuBottom from '../MobileBottomMenu';
import CookieConsent from 'react-cookie-consent';
import c from '../../helpers/constants';
import { NavLink } from 'react-router-dom';
import { config } from '../../config/config';
import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';


const View = (props: any) => {

  const [showBanner, setShowBanner] = useState<boolean>(true);
  
  const setDeclineCookie = () => {
    localStorage.setItem('cookieconsent', 'false');
  };

  let cookieConsent = localStorage.getItem('cookieconsent');




  const getStatus = async () => {
    const response = await AppTrackingTransparency.getStatus();
    return response;
  }




  const requestPermission = async () => {
    const response = await AppTrackingTransparency.requestPermission();
    return response;
  };


  const isRunningOnNative = Capacitor.isNativePlatform();



  const requestTrackingPermission = async () => {
    try {
      const statusRes = await getStatus();
      const status = statusRes.status;

      if (status === 'notDetermined') {
        const result = await requestPermission();
        const resText = result.status;
        if (resText === 'authorized') {
          localStorage.setItem('cookieconsent', 'true');
          setShowBanner( false );
        } else {
          setShowBanner( false );
          localStorage.setItem('cookieconsent', 'false');
          let x = document.getElementById('tagmanager');
          if (x != null && x != undefined) {
            x.remove();
          }
          let y = document.getElementById('tagmanager-script');
          if (y != null && y != undefined) {
            y.remove();
          }
        }
      } else if (status == 'denied') {
        setShowBanner( false );
        //Remove the cookie stuff 
        let x = document.getElementById('tagmanager');
        if (x != null && x != undefined) {
          x.remove();
        }
        let y = document.getElementById('tagmanager-script');
        if (y != null && y != undefined) {
          y.remove();
        }
      }else{
        setShowBanner( false );
      }
    } catch (error) {
      console.error('Error requesting tracking permission', JSON.stringify(error));
    }
  };

  useEffect(() => {
    if(isRunningOnNative){
        requestTrackingPermission(); 
    }
  }, [])


  let presentBanner = false; 

  if( isRunningOnNative ){
    presentBanner = showBanner;
  }
  else{
    presentBanner = cookieConsent != 'false' && cookieConsent != 'true';
  }


  return (
    <div>
      <div className="">
        <div className="">
          <div className="no-padding">
            <Header />
            <div className="main">{props.children}</div>
            <div className="footer-mobile-nav">
              <MobileMenuBottom />
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {
        presentBanner &&
        <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='cookieconsentCookie'
          style={{ background: '#403f3e', paddingLeft: '20px', paddingRight: '20px' }}
          buttonStyle={{ background: config.fillColour, color: '#FFF', borderRadius: '10px', fontSize: '14px' }}
          expires={150}
          enableDeclineButton
          declineButtonText='Opt Out'
          onAccept={() => {
            setDeclineCookie();
          }}
          onDecline={() => {

            setDeclineCookie();

            let x = document.getElementById('tagmanager');
            if (x != null && x != undefined) {
              x.remove();
            }
            let y = document.getElementById('tagmanager-script');
            if (y != null && y != undefined) {
              y.remove();
            }
          }}
        >
          This website uses cookies to enhance the user experience. <NavLink to={c.APP_ROUTES.COOKIES}>Learn More</NavLink>
        </CookieConsent>
      }
    </div>
  );
};

export default View;
