import axios from "axios";

export class PdfHelper {


    async resolveLogoByBrand(brand: any) {

        let img = new Image();
        img.src = brand;

        brand = brand.split('/');
        let url = brand[brand.length - 1];

        const response = await fetch(`/logos/${url}`);
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);

        const base64String = await this.convertBlobToBase64(blob);
        return base64String;
    }

    convertBlobToBase64(blob: any ) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    getPrimaryColour(company: string) {


        let lw = ['LW', 'LWN', 'FFD', 'LSC', 'LWMID', 'LEWSOUTH', 'LWS', 'LWMATNEW', 'SUDMAT', 'LEWSOUTH', 'BAS', 'LSE', 'SUD', 'LEWLONDN', 'HO'];

        if (lw.indexOf(company) > -1) {
            return '#e26329';
        }


        if (company == 'DELTA') {
            return '#c40d3c';
        }
        if (company == 'HAV' || company == 'HVHO' || company == 'HVSCO') {
            return '#747474';
        }

        return '#747474';

    }

    getSecondaryColour(company: string) {

        let lw = ['LW', 'LWN', 'FFD', 'LSC', 'LWMID', 'LEWSOUTH', 'LWS', 'LWMATNEW', 'SUDMAT', 'LEWSOUTH', 'BAS', 'LSE', 'SUD', 'LEWLONDN', 'HO'];

        if (lw.indexOf(company) > -1) {
            return '#333333';
        }

        if (company == 'DELTA') {
            return '#060f41';
        }

        if (company == 'HAV' || company == 'HVHO' || company == 'HVSCO') {
            return '#434975';
        }

        return '#2B2B2B';
    }


    toTitleCase(str: any) {
        return str.replace(
            /\w\S*/g,
            function (txt: any) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }


    getDate() {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = currentDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }

    getTableLayouts() {
        return {};
    }

    rightTrim(str: any, charToRemove: any) {
        const escapedChar = charToRemove.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special regex chars
        const regex = new RegExp(escapedChar + '+$'); // Match the specific character at the end
        return str.replace(regex, '');
    }
}