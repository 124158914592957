import React from 'react';
import './DashboardBannerItem.scss';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

declare let window: any;

type Props = {
  image: string;
  mobImage: string;
  link: string;
  active: boolean;
};
const DashboardBannerItem = (props: Props) => {

  const { image, mobImage, link, active } = props;

  const isRunningOnNative = Capacitor.isNativePlatform();

  const isFile = link.includes('.') && link.split('.').pop()?.length! > 0;

  const openBrowser = (url: string) => {
    Browser.open({ url: url });
  };


  const doClick = (link: string) => {

    if (isFile) {
      if (isRunningOnNative) {
        openBrowser(link);
      } else {
        window.open(link, '_blank');
      }
    } else {
      window.location.href = link;
    }
  }




  return (
    <div className={active == true ? 'dashbanner--item active' : 'dashbanner--item'}>
      {link != '' && null != link ? <a onClick={() => doClick(link)}></a> : ''}
      <div className='dasboardbanner--image'>
        {/* Previously using a background-img
        <div className='dasboardbanner--image' style={{
        backgroundImage: `url("${image}")`,
      }}> */}
        <img src={image} />
      </div>
      <div className='dasboardbanner--image__mob'>
        {/* Previously using a background-img
        <div className='dasboardbanner--image__mob' style={{
        backgroundImage: `url("${mobImage}")`,
      }}> */}
        <img src={mobImage}></img>
      </div>
    </div>
  );
};

export default DashboardBannerItem;
