import React, { useEffect, useState } from 'react';
import './SelectBank.scss';
import { getAvailableBanks } from '../../selectors/AvailableBanks';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailableBanks, payByBank, payInvoicesByBank } from '../../actions/AvailableBanks';
import Button from '../Button';
import { getReturnLines } from '../../selectors/Order';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { config } from '../../config/config';
import { Payit } from '../../helpers/icons';


type Props = {
    orderNumber?: number,
    orderValue: number,
    type: 'statement' | 'order';
    data?: any
};
const SelectBank = (props: Props) => {


    const { orderNumber, orderValue, type, data } = props;


    const dispatch = useDispatch();
    const bankObj = useSelector(getAvailableBanks);
    const [selectedBank, setSelectedBank] = useState<any>(null);
    const [showconsent, setShowConsent] = useState<boolean>(false);
    let banks = bankObj ? bankObj.banks : [];
    const termsLink = bankObj ? bankObj.paymentTerms : '';
    const [loading, setLoading] = useState<boolean>(false);

    if (banks) {
        banks.sort((x, y) => x.BankName.localeCompare(y.BankName));
    }

    let filteredBanks = banks ? banks.filter(x => x.BankTemporarilyUnavailable == false) : [];

    useEffect(() => {
        dispatch(fetchAvailableBanks());
    }, []);



    const selectBank = (bank: any) => () => {
        //We need to trigger an action here 
        console.log(bank);
        //  await triggerPaymentAction(); 
        setSelectedBank(bank);
    };


    const goToNext = () => {
        if (!selectedBank) {
            alert('Please select a bank');
            return;
        }
        setShowConsent(true);
    };

    const isRunningOnNative = Capacitor.isNativePlatform();

    const openTerms = () => {
        if (isRunningOnNative) {
            Browser.open({ url: termsLink });
            return;
        }
        window.open(termsLink, '_blank');
    };

    const cancel = () => {
        setShowConsent(false);
    }

    const complete = () => {
        setLoading(true);
        if (type == 'statement') {
            dispatch(payInvoicesByBank(data, props.orderValue, selectedBank.BankID));
        } else {
            dispatch(payByBank(props.orderNumber as number, props.orderValue, selectedBank.BankID));
        }
        setLoading(false);
    }

    return (
        <div className={'d-table w-100 select-bank'}>
            <div className='select-bank-header'>
                <div className="payment-modal__icon d-flex justify-content-between align-items-center">
                    <div className="payit-brand">
                        {
                            Payit()
                        }
                    </div>
                    <div className="payit-by">
                        Powered by Natwest
                    </div>
                </div>
            </div>
            <div className='custom-modal-body'>

                {
                    showconsent &&
                    <p>
                        Payit™ is provided by NatWest. By continuing, you agree to NatWest initiating the payment(s) and agree to these <a onClick={() => openTerms()} className='text-orange'>terms</a>.
                    </p>
                }
                {!showconsent &&
                    <div className='bank-list'>
                        {
                            filteredBanks.map((bank, index) => (
                                <div key={index} onClick={selectBank(bank)} className={selectedBank == bank ? 'bank-item item-selected' : 'bank-item'}>
                                    <img src={bank.LogoUrl} alt={bank.BankName} />
                                    <span>{bank.BankName}</span>
                                </div>
                            ))
                        }

                    </div>
                }
                {!showconsent &&
                    <div className='text-center mt-3'>
                        <Button text="Next" shape={'chamfer'} onClick={() => goToNext()} disabled={selectedBank == null} size={'md'}></Button>
                    </div>
                }
                {
                    loading && <>


                    </>
                }
                {showconsent &&
                    <div className='text-center mt-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <Button text="Cancel" shape={'chamfer'} onClick={() => cancel()} size={'md'} type={'grey'}></Button>
                            </div>
                            <div>
                                <Button text="Continue" shape={'chamfer'} onClick={() => complete()}></Button>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </div>
    );
};

export default SelectBank;
