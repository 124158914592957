import React, { useEffect, useState } from 'react';
import '../styles/MyStatements.scss';
import View from '../components/View';
import { useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import { useQuery } from '../helpers/functions';
import DropDown from '../components/DropDown';
import MyStatementsBar from '../components/MyStatementsBar';
import { CheckSquare, CheckSquareBlank, DownloadIcon, EllipsisIcon } from '../helpers/icons';
import { INVOICES } from '../helpers/testdata';
import ContextMenu from '../components/ContextMenu';
import { set } from 'lodash';
import LWModal from '../components/Modal';
import PaymentModal from '../components/PaymentModal';
import { config } from '../config/config';
import LwBankModal from '../components/LwBankModal';
import SelectBank from '../components/SelectBank';
import { getInvoice } from '../actions/AvailableBanks';
import { toast } from 'react-toastify';
import { InvoiceDefinition } from '../renderer/invoice';

declare let window: any;


const MyStatements = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNumberInput, setShowNumberInput] = useState<boolean>(false);
    const [showBankModal, setShowBankModal] = useState<boolean>(false);
    const enablePayments = config.hasOwnProperty('enablePayments') ? config.enablePayments : false;
    const [paymentData, setPaymentData] = useState<any>(null);

    const filterOptions = [{
        label: 'Outstanding Invoices',
        value: 'outstanding'
    }];

    let total = 0;

    let data = INVOICES;
    const balance = 9999999;

    total = data.invoices.length;

    const handleChildEvent = async (data: any) => {
        setShow(false);
        if (null != data) {
            //Do the action 
            if (data.action == 'download') {
                await navigateToInvoice(selectedOption);
            }
            setSelectedOption('');
        }
    };


    const doClickAction = (type: string) => {
        if (type == 'any') {
            setShowNumberInput(true);
        } else {
            setShowNumberInput(false);
        }
        setShowModal(true);
    };

    const toggleCheckAll = () => {
        if (checkedAll) {
            setCheckedItems([]);
        } else {
            setCheckedItems(data.invoices.map((item: any) => item.invoice_no));
        }
        setCheckedAll(!checkedAll);
    };

    const isToggled = (invoiceNo: string) => {
        let idx = checkedItems.indexOf(invoiceNo);
        if (idx > -1) {
            return true;
        }
        return false;
    };

    const toggleItem = (invoiceNo: string) => {
        let idx = checkedItems.indexOf(invoiceNo);
        let items = [];
        if (idx > -1) {
            checkedItems.splice(idx, 1);
        } else {
            checkedItems.push(invoiceNo);
        }
        items = [...checkedItems];
        setCheckedItems(items);
    };

    const [filter, setFilter] = useState<any>(filterOptions[0]);

    const navigateToInvoice = async (invoiceNo: any) => {
        let inv = await getInvoice(invoiceNo);


        if (!inv) {
            toast.error('An error occurred loading your invoice', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        let definition = new InvoiceDefinition();
        await definition.generate(inv.dsInvoice, invoiceNo);



        console.log(inv);
    };

    const showMenu = (e: any, orderNumber: string) => {
        setX(e.pageX);
        setY(e.pageY);
        setShow(true);
        setSelectedOption(orderNumber)
    }

    const onPaymentAction = (data: any) => {
        setShowModal(false);
        setPaymentData(data);
        setShowBankModal(true);
    };

    const menuOptions: any[] = [
        { text: 'Download Invoice', action: 'download', icon: DownloadIcon() }
    ];


    let checkedTotal = data.invoices
        .filter(x => checkedItems.indexOf(x.invoice_no as string) > -1)
        .map(y => parseFloat((y.amount as string).replace('£', '').replace(',', ''))) // Remove '£' and convert to number
        .reduce((acc, val) => acc + val, 0); // Sum up the amounts

    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);

    return (
        <View>
            <div className="grey-container">
                <div className="container my-statements">

                    <div className='my-statements-header'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><h3>My Statements</h3></div>
                            <div><div className='account-balance'>Account Balance : &pound;{balance.toFixed(2)}</div></div>
                        </div>
                    </div>


                    <MyStatementsBar total={checkedTotal} numberOfInvoices={checkedItems.length} clickEvent={doClickAction}></MyStatementsBar>



                    <div className='tab-row d-flex justify-content-between align-items-center'>
                        <div className='tabs-left'>
                            <ul>
                                <li>
                                    <a className='selected'>All</a>
                                </li>
                                <li>
                                    <a>Current</a>
                                </li>
                                <li>
                                    <a>30 Days</a>
                                </li>
                            </ul>
                        </div>
                        <div className='tabs-right'>
                            <DropDown items={filterOptions} value={filter}

                                onSelect={(item) => {

                                }} />
                        </div>
                    </div>

                    <div className='payments-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th className='checkbox-col'>
                                        <span className={checkedAll ? 'cursor fill-square checkbox' : 'checkbox cursor'} onClick={() => toggleCheckAll()}>
                                            {
                                                checkedAll ?
                                                    CheckSquare() :
                                                    CheckSquareBlank()
                                            }
                                        </span>
                                    </th>
                                    <th>Invoice No.</th>
                                    <th>Reference</th>
                                    <th>Due</th>
                                    <th>Amount</th>
                                    <th>Outstanding</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.invoices.map((s, i) =>
                                    <tr>
                                        <td className='checkbox-col'>
                                            <span className={isToggled(s.invoice_no as string) ? 'cursor fill-square checkbox' : 'checkbox cursor'} onClick={() => toggleItem(s.invoice_no as string)}>

                                                {
                                                    isToggled(s.invoice_no as string) ?
                                                        CheckSquare() :
                                                        CheckSquareBlank()
                                                }
                                            </span>
                                        </td>
                                        <td><a onClick={() => navigateToInvoice(s.invoice_no)}>{s.invoice_no}</a></td>
                                        <td>Ref:  {s.reference}</td>
                                        <td>{s.due_date}</td>
                                        <td>&pound;{s.amount}</td>
                                        <td>&pound;{s.outstanding}</td>
                                        <td>
                                            <a className='cursor ellipsis' onClick={(evt: any) => showMenu(evt, s.invoice_no as string)}>{
                                                EllipsisIcon()
                                            }</a>
                                        </td>
                                    </tr>
                                )
                                }

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4}>
                                        {total} Invoice{total == 1 ? '' : 's'}
                                    </td>
                                    <td>
                                        Total
                                    </td>
                                    <td>
                                        £0.00
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>

                    <div className='pb-4'>
                        <MyStatementsBar total={checkedTotal} numberOfInvoices={checkedItems.length} clickEvent={doClickAction}></MyStatementsBar>
                    </div>
                </div>
            </div>


            <LwBankModal title="" byText='' onClose={() => {
                setShowModal(false);
            }}
                show={showModal}>
                <>
                    <PaymentModal checkedItems={checkedItems} data={data} showNumberInput={showNumberInput} balance={balance} doPaymentAction={onPaymentAction}></PaymentModal>
                </>
            </LwBankModal>



            {
                (enablePayments) && <>
                    <LwBankModal title="Select Your Bank" byText='by Natwest' onClose={() => {
                        setShowBankModal(false);
                    }}
                        show={showBankModal}>
                        <SelectBank type='statement' data={paymentData} orderValue={paymentData?.value}></SelectBank>
                    </LwBankModal>
                </>

            }


            <ContextMenu x={x} y={y} show={show} options={menuOptions} clickEvent={handleChildEvent}></ContextMenu>
        </View>
    );
};

export default MyStatements;
