import { CopyIcon } from '../../helpers/icons';
import Button from '../Button';
import './MyStatementsBar.scss';

type Props = {
    total: number;
    numberOfInvoices: number;
    clickEvent?: ( type:string ) => void;
};


const MyStatementsBar = (props: Props) => {


    const {
        total = 0.00,
        numberOfInvoices = 0,
        clickEvent = (type:string ) => { },
    } = props;
    return (
        <div className='my-statements-bar bg-light-grey'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='my-statements-bar__title'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <div className='invoices-selected-div'>
                            <div className='d-flex justify-content-start align-items-center'>
                                <span className='copy-icon'>{CopyIcon()}</span>
                                <span>{numberOfInvoices} Invoices Selected</span>
                            </div>
                        </div>
                        <div> Total : £{total}</div>
                    </div>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>
                        <Button text="Pay Other Amount" type="grey" size='md' shape='chamfer' onClick={() => clickEvent('any')} />
                    </div>
                    <div>
                        <Button text="Pay Invoices" type="orange" size='md' shape='chamfer'  onClick={() => clickEvent('specific')} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MyStatementsBar;
