export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_RESPONSE = 'FETCH_ORDERS_RESPONSE';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_RESPONSE = 'PLACE_ORDER_RESPONSE';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const FETCH_ORDERS_AMEND = 'FETCH_ORDERS_AMEND';
export const FETCH_ORDERS_AMEND_REQUEST = 'FETCH_ORDERS_AMEND_REQUEST';

export const ADD_TO_BASKET = 'ADD_TO_BASKET';

export const CLEAR_BASKET = 'CLEAR_BASKET';

export interface Order {
  order_number: number;
  order_line: number;
  date_ordered: string;
  product_sku: string;
  product_desc: string;
  product_type_code: string;
  qty_ordered: number,
  units_of_measure: string;
  LENGTH: number;
  WIDTH: number;
  half_roll: string;
  line_reference: string;
  line_price: number;
  line_discount: number;
  line_value: number;
  line_vat_value: number;
  line_delivery_date: boolean;
  collection: boolean;
  order_reference: string;
  delivery_date: string;
  order_status: string;
  invoice_number: number;
  sqm: number;
  order_sub_status: string;
  price_per_sqm: number;
}

export interface SoftOrder {
  orderLines: OrderLine[];
  deliveryCharge: number;
  deliveryDates: string[];
  return_code?: string;
  return_desc?: string;
  return_lines?: [];
}

export interface OrderLine {
  uid: string; 
  product_sku: string;
  quantity: number;
  length: number;
  width: number;
  roll_id: string;
  pallet_id: string;
  half_roll: string;
  line_reference: string;
  line_price: number;
  line_value?: number;
  item_price: number;
  line_discount: number;
  price_per_sqm?: number;
  product_quality: string;
  product_type_code: string;
  product_type_code_name: string;
  colour_full_name?: string;
  line_availability?: string;
  line_delivery_date: string;
  collection: boolean;
  carriage_charge_linit?: number;
  carriage_charge_units?: number;
  carriage_charge_coir?: number;
  po_dates?: any[];
  presold?: boolean; 
  cart_date?:string;
}

export interface OrderState {
  orders: Order[],
  basket: OrderLine[],
  return_code: string;
  return_desc: string;
  return_lines: [];
  deliveryDates: string[],
  isFetchingOrders: boolean;
  fetchingOrdersFailed: boolean;
  deliveryCharge: number;
}

interface AddToBasketAction {
  type: typeof ADD_TO_BASKET;
  payload: SoftOrder;
}

interface ClearBasketAction {
  type: typeof CLEAR_BASKET;
  payload: null;
}

interface FetchOrdersRequestAction {
  type: typeof FETCH_ORDERS_REQUEST;
  payload: null;
}

interface FetchOrderAmendRequest {
  type: typeof FETCH_ORDERS_AMEND_REQUEST;
  payload: null;
}



interface FetchOrdersResponseAction {
  type: typeof FETCH_ORDERS_RESPONSE;
  payload: Order[];
}
interface FetchOrdersAmendAction {
  type: typeof FETCH_ORDERS_AMEND;
  payload: Order[];
}

interface FetchOrdersFailureAction {
  type: typeof FETCH_ORDERS_FAILURE;
  payload: null;
}

interface PlaceOrdersRequestAction {
  type: typeof PLACE_ORDER_REQUEST;
  payload: null;
}

interface PlaceOrdersResponseAction {
  type: typeof PLACE_ORDER_RESPONSE;
  payload: null;
}

interface PlaceOrdersFailureAction {
  type: typeof PLACE_ORDER_FAILURE;
  payload: null;
}

export type OrderActionTypes =
  | FetchOrdersRequestAction
  | FetchOrdersResponseAction
  | FetchOrdersFailureAction
  | PlaceOrdersRequestAction
  | PlaceOrdersResponseAction
  | PlaceOrdersFailureAction
  | AddToBasketAction
  | ClearBasketAction 
  | FetchOrdersAmendAction 
  | FetchOrderAmendRequest;
