export const FETCH_AVAILABLE_BANKS_REQUEST = 'FETCH_AVAILABLE_BANKS_REQUEST';
export const FETCH_AVAILABLE_BANKS_RESPONSE = 'FETCH_AVAILABLE_BANKS_RESPONSE';
export const FETCH_AVAILABLE_BANKS_FAILURE = 'FETCH_AVAILABLE_BANKS_FAILURE';

export interface AvailableBanks {
    BankName: string;
    LogoUrl: string;
    BankID: string;
    BankTemporarilyUnavailable: boolean;
}

export interface AvailableBankObj {
  banks: AvailableBanks[];
  paymentTerms: string;
}

export interface AvailableBanksState {
  availableBanks: AvailableBankObj | null,
  isFetchingAvailableBanks: boolean;
  fetchingAvailableBanksFailed: boolean;
}

interface FetchAvailableBanksRequestAction {
  type: typeof FETCH_AVAILABLE_BANKS_REQUEST;
  payload: null;
}

interface FetchAvailableBanksResponseAction {
  type: typeof FETCH_AVAILABLE_BANKS_RESPONSE;
  payload: AvailableBankObj;
}

interface FetchAvailableBanksFailureAction {
  type: typeof FETCH_AVAILABLE_BANKS_FAILURE;
  payload: null;
}

export type AvailableBanksAction =
  | FetchAvailableBanksRequestAction
  | FetchAvailableBanksResponseAction
  | FetchAvailableBanksFailureAction;
