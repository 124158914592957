import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, getBrowserAndDeviceInfo, getCustomerBranch, getCustomerID } from '../helpers/functions';

export function getAvailableBanks(customerId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.GET_BANKS}?customerid=${encodeURIComponent(customerId)}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

/**
 * Trigger Order Payment 
 * @param orderNumber 
 * @returns 
 */
export function triggerPaymentAction(orderNumber: any) {

  let dv = getBrowserAndDeviceInfo();

  let payload = {
    order_number: orderNumber,
    customerNumber: getCustomerID(),
    branch: getCustomerBranch(), 
    device: dv.device,
    browser: dv.browser
  };
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER_PAYMENT}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
      data: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

/**
 * Trigger Order Payment 
 * @param orderNumber 
 * @returns 
 */
export function triggerBankPaymentAction(orderNumber: number, ordeValue: number, bankId: string) {

  let dv = getBrowserAndDeviceInfo();

  let payload = {
    order_number: orderNumber,
    customer_number: getCustomerID(),
    branch: getCustomerBranch(), 
    device: dv.device,
    browser: dv.browser,
    bank_id: bankId,
    order_value: ordeValue
  };
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER_PAYMENT}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': getApiKey()
      },
      data: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}



/**
 * Trigger Order Payment 
 * @param orderNumber 
 * @returns 
 */
export function triggerStatementPayment(payload: any, ordeValue: number, bankId: string) {

  let dv = getBrowserAndDeviceInfo();

   payload = {
    customer_number: getCustomerID(),
    branch: getCustomerBranch(), 
    device: dv.device,
    browser: dv.browser,
    bank_id: bankId,
    order_value: ordeValue,
    type: 'statement',
    ...payload
  };

  return (
    axios({
      url: `${c.API_ENDPOINTS.STATEMENT_PAYMENT}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': getApiKey()
      },
      data: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

