import {
    FETCH_AVAILABLE_BANKS_REQUEST,
    FETCH_AVAILABLE_BANKS_RESPONSE,
    FETCH_AVAILABLE_BANKS_FAILURE,
    AvailableBanksState,
    AvailableBanksAction,
  } from '../types/AvailableBanks';

  const initialState: AvailableBanksState = {
    availableBanks: null,
    isFetchingAvailableBanks: false,
    fetchingAvailableBanksFailed: false,
  };
  
  const availableBanksReducer = (
    state = initialState,
    action: AvailableBanksAction,
  ): AvailableBanksState => {
    switch (action.type) {
      case FETCH_AVAILABLE_BANKS_REQUEST:
        return {
          ...state,
          availableBanks: null,
          isFetchingAvailableBanks: true,
          fetchingAvailableBanksFailed: false,
        };
      case FETCH_AVAILABLE_BANKS_RESPONSE:
        return {
          ...state,
          availableBanks: action.payload || null,
          isFetchingAvailableBanks: false,
          fetchingAvailableBanksFailed: false,
        };
      case FETCH_AVAILABLE_BANKS_FAILURE:
        return {
          ...state,
          availableBanks: null,
          isFetchingAvailableBanks: false,
          fetchingAvailableBanksFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default availableBanksReducer;
  