export const FETCH_THANKYOU_REQUEST = 'FETCH_THANKYOU_REQUEST';
export const FETCH_THANKYOU_RESPONSE = 'FETCH_THANKYOU_RESPONSE';
export const FETCH_THANKYOU_FAILURE = 'FETCH_THANKYOU_FAILURE';

export interface ThankYouObj {
    data: any;
    created_at: string;
    customer_id: string;
    transaction_id: string;
    type: string;
    status: string
}

export interface AvailableBankObj {
  response: ThankYouObj;
  paymentTerms: string;
}

export interface ThankyouState {
  thankyou: ThankYouObj | null,
  isFetchingThankyou: boolean;
  fetchingThankyouFailed: boolean;
}

interface FetchThankyouRequestAction {
  type: typeof FETCH_THANKYOU_REQUEST;
  payload: null;
}

interface FetchThankyouResponseAction {
  type: typeof FETCH_THANKYOU_RESPONSE;
  payload: ThankYouObj;
}

interface FetchThankyouFailureAction {
  type: typeof FETCH_THANKYOU_FAILURE;
  payload: null;
}

export type ThankyouActionTypes =
  | FetchThankyouRequestAction
  | FetchThankyouResponseAction
  | FetchThankyouFailureAction;
