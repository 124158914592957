import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_THANKYOU_FAILURE,
  FETCH_THANKYOU_RESPONSE,
  FETCH_THANKYOU_REQUEST,
  ThankyouActionTypes,
  ThankYouObj
} from '../types/Thankyou';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';
import { fetchThankyouDetails } from '../api/ThankyouApi';

export function fetchThankyouRequest(): ThankyouActionTypes {
  return {
    type: FETCH_THANKYOU_REQUEST,
    payload: null,
  };
}

export function fetchThankyouResponse(
  Thankyou: ThankYouObj,
): ThankyouActionTypes {
  return {
    type: FETCH_THANKYOU_RESPONSE,
    payload: Thankyou,
  };
}

export function fetchThankyouFailure(): ThankyouActionTypes {
  return {
    type: FETCH_THANKYOU_FAILURE,
    payload: null,
  };
}

export const fetchThankyou = ( params : any ):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchThankyouRequest());
    if (!isAuthenticated()) {
      try {
        history.push(c.APP_ROUTES.LOGIN);
      } catch (e) {
      }
    }
    console.log('async');
    const asyncResp: any = await fetchThankyouDetails(params);

    if (asyncResp) {
      await dispatch(fetchThankyouResponse(asyncResp));
    } else {
      await dispatch(fetchThankyouFailure());
    }
  };

