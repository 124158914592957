import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseButtonSVG from '../../assets/svgs/modal-close.svg';
import './LwBankModal.scss';

type Props = {
  title: string;
  byText: string;
  children: any,
  onClose: () => void;
  show: boolean;
};
const LwBankModal = (props: Props) => {
  return (
    <Modal size="lg" show={props.show} contentClassName='bank-modal'>  
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default LwBankModal;
