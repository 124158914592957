import React, { useEffect, useState } from 'react';
import '../styles/DeleteAccount.scss';
import View from '../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerID, setCustomerBranch } from '../helpers/functions';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import { emailDeleteAccount } from '../actions/Email';
import DropDown from '../components/DropDown';
import { getCustomer } from '../selectors/Customer';


const DeleteAccount = () => {
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState(false);
  const [cname, setCName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const customer = useSelector(getCustomer);

  const reasons = ['Business Closed', 'Business Moved', 'Business Sold', 'Unhappy with Products', 'Unhappy with Service', 'Unhappy with Pricing', 'Other'];
  
  useEffect(() => {
    setCustomerId(getCustomerID() as string);
  }, [dispatch]);
  
  
  const onDeleteAccount = async () => {
    await dispatch(emailDeleteAccount(
      {
        companyName: cname,
        customerId: customerId,
        password: password,
        reason: reason,
        branchEmail: customer?.branch_email || ''
      },
    ));
    setCustomerId('');
    setCustomerBranch('');
    setCName('');
    setPassword('');
    setReason('');
    setEmailSent(true);
  };
   
    
  return (
        <View>
            <div className="half-grey-container">
                <div className="container myaccount">
                    <div className="myaccount-header delete-account__form--header">
                        <div className="myaccount-header__title">
                            <h3>Delete Account</h3>
                        </div>
                    </div>
                    <div className="delete-account__form">
                        <div className="white-container">
                            <h4 className='delete-account__form--title'>Request to Delete Account</h4>
                            <p className="delete-account__form--summary">Complete the form below and our admin team will aim to deal with your request within 48 hours.</p>
                                {!emailSent &&
                                    <div>
                                    
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <TextInput id="name" bgColor="grey" name="name"
                                                    value={cname} onBlur={(e) => setCName(e)}
                                                    onChange={(e) => setCName(e)}
                                                    placeholder="Company Name" />
                                            </div>
                                        </div>
                                        <div className="row  mb-3">
                                            <div className="col-12">
                                                <TextInput id="customerId" bgColor="grey" name="customerId" disabled={ true }
                                                    value={customerId} onBlur={(e) => setCustomerId(e)}
                                                    onChange={(e) => setCustomerId(e)}
                                                    placeholder="Account No." />
                                            </div>
                                        </div>
                                        <div className="row  mb-3">
                                            <div className="col-12">
                                            <DropDown items={reasons?.map(d => ({ label: d, value: d }))} value={reason} onSelect={(item) => (setReason(item.value))} placeholder={ 'Reason' } />

                                  
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <TextInput id="password" bgColor="grey" name="password"
                                                    value={password} onBlur={(e) => setPassword(e)}
                                                    onChange={(e) => setPassword(e)}
                                                    type="password"
                                                    placeholder="Password" />
                                            </div>
                                        </div>
                                    
                                        <div className="row">
                                            <Button text="Submit Request" disabled={!customerId || !cname || !password } onClick={onDeleteAccount} />
                                        </div>
                                    </div>
                                }
                                {emailSent && <div className="alert alert-success text-center" role="alert">
                                    Thank you for your request, our team will be in touch soon.
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </View>
  );
};

export default DeleteAccount;
